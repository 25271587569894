import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { authActions } from "@/store/actions/auth.actions";
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import appHeaderStyles from '@Templates/header/AppHeader/AppHeader.module.sass';
import url from "@/router/urls";

const HeaderMyAccount = () => {
	const { t } = useTranslation();
	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const isProtected = useSelector((state) => state.auth.path.isProtected);
	const formsAutorization = useSelector((state) => state.app.config.authorization == "FORMS");
	const authorizationLoginUrl = useSelector((state) => state.app.config.reactAppApi + state.app.config.authorizationLoginUrl);
	const reactAppApi = useSelector((state) => state.app.config.reactAppApi);
	const authorizationRegisterUrl = useSelector((state) => state.app.config.reactAppApi + state.app.config.authorizationRegisterUrl);
	const UserType = useSelector((state) => state.auth.credentials.securityGroup);
	const dispatch = useDispatch();

	const signOut = () => {
		dispatch(authActions.signOut(reactAppApi, isProtected, formsAutorization));
	};

	return (
		<Dropdown alignRight>
			<Dropdown.Toggle
				id='dropdown-custom-1'
				className={appHeaderStyles["header-dropdown__button"]}
				variant={appHeaderStyles["header-dropdown__button"]}
			>{isLoggedIn ? t('headers.menu.myAccount') : t('headers.menu.logIn')}
			</Dropdown.Toggle>

			<Dropdown.Menu className={appHeaderStyles["header-dropdown__menu"]}>
				{isLoggedIn ? (
					<>
						<Link
							className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
							to={{ pathname: url.auth.profile, state: { isProtected: true } }}
						>
							{t('headers.menu.myAccountMenu.myProfile')}
						</Link>

						{CheckSecurityGroupPermission("canAccessProjectsDashboard", UserType) &&
							<Link
								className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
								to={{ pathname: url.auth.admin.projects, state: { isProtected: true } }}
							>
								{t('headers.menu.myAccountMenu.serviceManagement')}
							</Link>}
						{CheckSecurityGroupPermission("canAccessUsersDashboard", UserType) &&
							<Link
								className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
								to={{ pathname: url.auth.admin.users, state: { isProtected: true } }}
							>
								{t('headers.menu.myAccountMenu.usersManagement')}
							</Link>}
						<Dropdown.Item
							className={appHeaderStyles["header-dropdown__item"]}
							onClick={() => signOut()}
						>
							{t('headers.menu.logOut')}
						</Dropdown.Item>
					</>
				) : ( formsAutorization ?
					<>
						<Link
							className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
							to={{ pathname: url.auth.login, state: { isProtected: false } }}
						>
							{t('headers.menu.logIn')}
						</Link>
						<Link
							className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
							to={{ pathname: url.auth.register, state: { isProtected: false } }}
						>
							{t('headers.menu.createAccount')}
						</Link>
					</> : 
					<>
						<a
							className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
							href={ authorizationLoginUrl + "?returnUrl=" + encodeURIComponent(window.location) }
						>
							{t('headers.menu.logIn')}
						</a>
						<a
							className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
							href={ authorizationRegisterUrl + "?returnUrl=" + encodeURIComponent(window.location) }
						>
							{t('headers.menu.createAccount')}
						</a>
					</>
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default HeaderMyAccount;