import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { eventViewerActions } from '@/store/actions/eventViewer.actions';

import updateUrl from "@/utils/eventViewerHelper";

import PropTypes from 'prop-types';

const SearchInput = ({searchedPhrase, setSearchedPhrase, onSearch}) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const history = useHistory();

	const { eventId, pageId } = useParams();

	const searchResults = useSelector((state) => state.viewer.searchResults);

	const clearSearchInput = () => {
		setSearchedPhrase("");
		dispatch(eventViewerActions.setSearchResults("", { results: { resources: [], hits: [] } }));
		updateUrl(history, eventId, pageId, "");
	};

	const searchEvent = (phrase) => {
		dispatch(eventViewerActions.searchEventForPhrase(eventId, phrase));
		updateUrl(history, eventId, pageId, phrase);
	};

	return (
		<div className='viewer-footer__search'>
			{searchResults.searchedPhrase !== '' && (
				<i className='icon-close-circle' style={{color: "#C9E2F1"}} onClick={ () => clearSearchInput() } />
			)}
			{searchResults.searchedPhrase === '' && (
				<div className='search-button' onClick={ () => { searchEvent(searchedPhrase); onSearch(); } }>
					<i className='icon-search' />
				</div>
			)}
			<input
				type='text'
				className='viewer-footer__search--input'
				placeholder={t('viewer.footer.search')}
				value={searchedPhrase}
				onChange={e => {
					setSearchedPhrase(e.target.value);
				}}
				onKeyDown={e => {
					if(e.key === 'Enter'){
						searchEvent(searchedPhrase);
						onSearch();
					}
				}}
			/>
		</div>
	);
};

SearchInput.propTypes = {
	searchedPhrase: PropTypes.string, 
	setSearchedPhrase: PropTypes.func,
	onSearch: PropTypes.func,
};

export default SearchInput;