import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import api from "@/services/api";

import { useTranslation } from 'react-i18next';
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { alertActions } from "@/store/actions/alert.actions";

import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";
import styles from "@Pages/Event/Event.module.sass";

const ModalDeleteTeam = ({modalVisible, setModalVisible, eventId, teamForModal, setTeams, getEvent, dataToSend}) => {
	const [teamLetter, setTeamLetter] = useState('');
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const firstTeamName = "Drużyna A";
    
	const deleteTeam = async (teamToDelete) => {
		let data = dataToSend.filter(team => team.name != null);

		if(teamToDelete === firstTeamName) {
			data[0].members = [];
		} else {
			data = data.filter(team => team.name != teamToDelete);
		}

		try {
			const res = await api.put(`/events/${eventId}/teams`, data);

			if (res.status === 200) {
				dispatch(appActions.setLoading(false));
				setTeams(res.data);
				getEvent();
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.teams.deleteTeam.warning'),
				close: true,
			}));
		}
	};

	useEffect(() => {
		if(teamForModal) {
			setTeamLetter(teamForModal.charAt(teamForModal.length - 1));
		}   
	}, [teamForModal]);

	return (
		<ModalWrapperChildren
			modalVisible={modalVisible}
			setModalVisible={() => setModalVisible(false)}
			title={t('events.teams.deleteTeam.title', {teamLetter: teamLetter})}
			closeButtonName={t('events.teams.deleteTeam.cancel')}
			confirmButtonName={t('events.teams.deleteTeam.close')}
			onConfirm={() => {deleteTeam(teamForModal), setModalVisible(false);}}
			withFooter
		>
			<div className={ styles["teams__delete-team"] }>
				{t('events.teams.deleteTeam.description', {teamLetter: teamLetter})}
			</div>
		</ModalWrapperChildren>
	);
};

ModalDeleteTeam.propTypes = {
	modalVisible: PropTypes.bool, 
	setModalVisible: PropTypes.func, 
	eventId: PropTypes.string,
	teamForModal: PropTypes.string, 
	setTeams: PropTypes.func, 
	getEvent: PropTypes.func,
	dataToSend: PropTypes.array,
};

export default ModalDeleteTeam;