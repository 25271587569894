import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Row, useAccordionToggle } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

import { authActions } from "@/store/actions/auth.actions";
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import url from "@/router/urls";

import styles from "@Templates/header/AppHeader/HeaderTopBar.module.sass";

const CustomToggle = ({ children, eventKey, callback }) => {
	const currentEventKey = useContext(AccordionContext);
	const decoratedOnClick = useAccordionToggle(
		eventKey,
		() => callback && callback(eventKey),
	);

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<Button className={styles[isCurrentEventKey ? "card-header-toggle--active" : "card-header-toggle"]} variant='link' onClick={decoratedOnClick}>
			{children}
			{isCurrentEventKey ? <i className='icon-chevron-up' /> : <i className='icon-chevron-down' />}
		</Button>
	);
};

CustomToggle.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string,
	]),
	eventKey: PropTypes.string,
	callback: PropTypes.func,
};

const TopBarMenuRowMobile = () => {
	const { t } = useTranslation();
	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const isProtected = useSelector((state) => state.auth.path.isProtected);
	const formsAutorization = useSelector((state) => state.app.config.authorization == "FORMS");
	// const authorizationLoginUrl = useSelector((state) => state.app.config.reactAppApi + state.app.config.authorizationLoginUrl);
	const reactAppApi = useSelector((state) => state.app.config.reactAppApi);
	// const authorizationRegisterUrl = useSelector((state) => state.app.config.reactAppApi + state.app.config.authorizationRegisterUrl);
	const UserType = useSelector((state) => state.auth.credentials.securityGroup);
	const dispatch = useDispatch();

	const signOut = () => {
		dispatch(authActions.signOut(reactAppApi, isProtected, formsAutorization));
	};

	return (
		<>
			<Row className={styles["top-bar__row--menu"] + " " + styles["mobile-top-bar"]}>
				<Accordion defaultActiveKey={null}>
					<Card className={styles["custom-card"]}>
						<Card.Header className={styles["custom-card-header"]}>
							<CustomToggle eventKey='0'>{t('headers.menu.projects')}</CustomToggle>
						</Card.Header>
						<Accordion.Collapse eventKey='0'>
							<Card.Body className={styles['custom-card-body']}>
								<Link
									className={styles['custom-card-item']}
									to={{ pathname: url.transcriptionsUser, state: { isProtected: true } }}
								>
									{t('headers.menu.myProjects')}
								</Link>
								<Link
									className={styles['custom-card-item']}
									to={{ pathname: url.transcriptions, state: { isProtected: false } }}
								>
									{t('headers.menu.allProjects')}
								</Link>
								<Link
									className={styles['custom-card-item']}
									to={{ pathname: url.transcription.new, state: { isProtected: true } }}
								>
									{t('headers.menu.newProject')}
								</Link>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card className={styles["custom-card"]}>
						<Card.Header className={styles["custom-card-header"]}>
							<CustomToggle eventKey='1'>{t('events.events')}</CustomToggle>
						</Card.Header>
						<Accordion.Collapse eventKey='1'>
							<Card.Body className={styles['custom-card-body']}>
								<Link
									className={styles['custom-card-item']}
									to={{ pathname: url.events }}
								>{t('events.allEvents')}
								</Link>
								<Link
									className={styles['custom-card-item']}
									to={{ pathname: url.events }}
								>{t('events.myEvents')}
								</Link>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					{isLoggedIn && (
						<Card className={styles["custom-card"]}>
							<Card.Header className={styles["custom-card-header"]}>
								<CustomToggle eventKey='4'>{isLoggedIn ? t('headers.menu.myAccount') : t('headers.menu.logIn')}</CustomToggle>
							</Card.Header>
							<Accordion.Collapse eventKey='4'>
								<Card.Body className={styles['custom-card-body']}>
									<>

										<Link
											className={styles['custom-card-item']}
											to={{ pathname: url.auth.profile, state: { isProtected: true } }}
										>
											{t('headers.menu.myAccountMenu.myProfile')}
										</Link>
										{CheckSecurityGroupPermission("canAccessProjectsDashboard", UserType) &&
											<Link
												className={styles['custom-card-item']}
												to={{ pathname: url.auth.admin.projects }}
											>
												{t('headers.menu.myAccountMenu.serviceManagement')}
											</Link>}
										{CheckSecurityGroupPermission("canAccessUsersDashboard", UserType) &&
											<Link
												className={styles['custom-card-item']}
												to={{ pathname: url.auth.admin.users }}
											>
												{t('headers.menu.myAccountMenu.usersManagement')}
											</Link>}

										<div className={styles['custom-card-item__logout']}>
											<Button variant='secondary' onClick={() => signOut()}>
												{t('headers.menu.logOut')}
											</Button>
										</div>
									</>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					)}
				</Accordion>
				{!isLoggedIn && (
					<div className={styles['login-element']}>
						<Link
							to={{ pathname: url.auth.login }}
						>
							<Button variant='primary' style={{ width: '100%' }}>
								{t('headers.menu.logIn')}
							</Button>

						</Link>
						<div className={styles['create-account-wrapper']}>
							<p style={{ color: 'black' }}>{t('headers.menu.dontHaveAccount')}</p>
							<Link
								to={{ pathname: url.auth.register }}
							>
								<Button variant='link' style={{ width: '100%' }}>
									{t('headers.menu.createOneBt')}
								</Button>
							</Link>
						</div>
					</div>
				)}
			</Row>
		</>
	);
};

export default TopBarMenuRowMobile;