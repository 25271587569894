import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from "react-router-dom";
import { reverse } from "named-urls";

import Button from "@Elements/Button/Button";

import url from "@/router/urls";
import styles from "@Elements/Transcriptions/TranscriptionsItem.module.sass";

import imageOnError from "@Assets/images/icon-image.svg";

const EventTranscriptionItemPage = ({
	page,
	viewMode = "read-only",
	eventId,
	currentPage,
	matchesPerTranscription,
}) => {
	const { t } = useTranslation();

	const availablePageLayers = useMemo(() => {
		return [page.pageLayers[0]];
	}, [page.pageLayers]);

	const linkToTranscriptionView = reverse(url.eventViewer, {
		eventId: parseInt(eventId),
		pageId: parseInt(page.id),
	});

	const imageRef = useRef(null);
	
	const handleImageOnError = () => {
		imageRef.current.className = styles["transcription__page--image-error"];
		imageRef.current.style.backgroundImage = `url(${imageOnError})`;
	};

	return (
		<div className={styles["transcription__page--box"]}>
			<Link
				to={{
					pathname: linkToTranscriptionView,
					state: {
						paginationPage: currentPage,
					},
				}}
			>

				<div
					className={styles["transcription__page--image"]}
					style={{ backgroundImage: `url(${page.thumbnailUrl})` }}
					ref={imageRef}
				>
					{page.percentOfTranscribed === 100 && (
						<div className={styles["transcription__page--ribbon"]}>{t('transcriptions.item.fullOCR')}</div>
					)}

					{page.thumbnailUrl &&
					<img src={page.thumbnailUrl} alt={`transcription-${page.id}`} title={`transcription-${page.id}`} role='presentation'
						onError={handleImageOnError}
					/>}
					<span className={styles["transcription__page--span"]}>{page.id}</span>
				</div>
			</Link>
			<div className={styles["transcription__page--block-bottom"]}>
				<Link
					to={{
						pathname: linkToTranscriptionView,
						state: {
							paginationPage: currentPage,
						},
					}}
					className={styles["transcription__page--number"]}
				>
					{t('transcriptions.item.pageNo')} {page.pageNo}
				</Link>

				{availablePageLayers.map((pageItem, index) => (
					<div key={pageItem.layer.id} className={styles["transcription__page--progress"]}>
						<span className={styles["transcription__page--progress-text"]}>
							<span className={styles["transcription__page--progress-text-layer"]}>
								{t('viewer.layers.container.layer')} {index + 1}
							</span>
							<span className={styles["transcription__page--progress-text-long"]}>
								{t('transcriptions.item.name')}
								{pageItem.layer.name}
							</span>
						</span>
						<span className={styles["transcription__page--progress-bar"]}>
							<div className={styles["transcription__page--progress-bar--wrapper"]}>
								<span
									className={
										page.percentOfTranscribed === 100
											? styles["transcription__page--progress-bar--active"]
											: styles["transcription__page--progress-bar--meter"]
									}
									style={{ width: `${pageItem.percentOfTranscribed}%` }}
								/>
							</div>
							<span className={styles["transcription__page--progress-value"]}>
								{pageItem.percentOfTranscribed}%
							</span>
						</span>
					</div>
				))}

				{matchesPerTranscription &&
					`${t('transcriptions.item.matchesInText')} ${matchesPerTranscription.hitCount}`}
				<div className={styles["transcription__page__magicBox"]}>
					<Link
						to={{
							pathname: linkToTranscriptionView,
							state: {
								paginationPage: currentPage,
							},
						}}
					>
						<Button variant='secondary'>{viewMode == "read-only" ? t('transcriptions.item.show') : t('transcriptions.item.work')}</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};

EventTranscriptionItemPage.propTypes = {
	page: PropTypes.object,
	percentOfTranscribed: PropTypes.number,
	viewMode: PropTypes.string,
	eventId: PropTypes.number,
	currentPage: PropTypes.number,
	matchesPerTranscription: PropTypes.object,
};

export default React.memo(EventTranscriptionItemPage);
