import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";
import styles from "@Pages/Event/Event.module.sass";

const ModalDeleteUser = ({modalVisible, setModalVisible, userName, doDeleteUser}) => {
	const { t } = useTranslation();

	return (
		<ModalWrapperChildren
			modalVisible={modalVisible}
			setModalVisible={() => setModalVisible(false)}
			title={t('events.teams.deleteUser.title')}
			closeButtonName={t('events.teams.deleteUser.cancel')}
			confirmButtonName={t('events.teams.deleteUser.close')}
			onConfirm={() => {doDeleteUser(), setModalVisible(false);}}
			withFooter
		>
			<div className={ styles["teams__delete-event"] } >
				<span>{t('events.teams.deleteUser.body', {userName})}</span>
			</div>
		</ModalWrapperChildren>
	);
};

ModalDeleteUser.propTypes = {
	modalVisible: PropTypes.bool,
	setModalVisible: PropTypes.func,
	userName: PropTypes.string,
	doDeleteUser: PropTypes.func,
};

export default ModalDeleteUser;