import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import EventTranscriptionItemPage from '@Elements/Transcriptions/EventTranscriptionItemPage';
import Pagination from "@Components/Pagination/Pagination";

import Button from "@Elements/Button/Button";

import api from "@/services/api";

import styles from "@Pages/Event/Event.module.sass";

const Pages = ({ eventId, teamId, transcriptions }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);

	const [selectedTranscriptionId, setSelectedTranscriptionId] = useState(null);
	const [currentPages, setCurrentPages] = useState(null);

	const getTranscriptionPages = async (transcriptionId, page) => {
		try {
			const res = await api.get(`/events/${eventId}/transcriptions/${transcriptionId}/pages?teamId=${teamId}&includeUnassigned=false&perpage=24&page=${page}`);
			return res.data;
		} catch (error) {
			/*dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.pages.warnings.pagesForTeam'),
				close: true,
			}));*/
		}
	};

	useEffect(() => {
		async function fetchData() {
			const transcriptionPagesToSet = await getTranscriptionPages(selectedTranscriptionId, currentPage);
			setPageNumber(transcriptionPagesToSet.pages);
			setCurrentPages(transcriptionPagesToSet.list);
		}

		if (eventId && teamId && selectedTranscriptionId && currentPage) {
			fetchData();
		}
	}, [eventId, teamId, selectedTranscriptionId, currentPage]);

	useEffect(() => {
		if(transcriptions && transcriptions.length > 0) {
			setSelectedTranscriptionId(transcriptions[0].id);
		}
	}, [transcriptions]);

	return (
		<>
			<Container>
				<div className={styles["event__pages__buttons"]}>
					{transcriptions && transcriptions.map(trn => {
						return(
							<Button className={[styles[`event__pages__button-transcription`], trn.id === selectedTranscriptionId && styles[`event__pages__button-transcription--active`]]} 
								key={trn.id} onClick={() => {setSelectedTranscriptionId(trn.id); setCurrentPage(1);}}
							>
								<span title={trn.titleLabel}>{trn.titleLabel}</span>
							</Button>);
					})}
				</div>
			</Container>
			<div className={styles["event__pages__pages"]}>
				<Container>
					<Pagination
						currentPage={currentPage}
						pagesNum={pageNumber}
						onGoToPage={setCurrentPage}
					>
						<div className={styles["event__pages__row"]}>
							{currentPages && currentPages.map(page => {
								return (
									<div className={styles["event__pages__item"]} key={page.id}>
										<EventTranscriptionItemPage
											key={page.id}
											page={page}
											eventId={eventId}
											percentOfVerified={page.percentOfTranscribed}
										/>
									</div>
								);

							})}
						</div>
					</Pagination>
				</Container>
			</div>
		</>
	);
};

Pages.propTypes = {
	eventId: PropTypes.string,
	teamId: PropTypes.number,
	transcriptions: PropTypes.array,
};

export default Pages;