import React from 'react';
import PropTypes from "prop-types";

import api from "@/services/api";
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alertActions } from "@/store/actions/alert.actions";

import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";
import styles from "@Pages/Event/Event.module.sass";

const ModalRandomUsers = ({modalVisible, setModalVisible, teams, eventId, setTeams, getEvent}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const randomUsers = async () => {	
		const users = teams.reduce((result, team) => {
			team.members.map(member => result.push(member));
			return result;
		}, []);

		const shuffleUsers = users
			.map(value => ({ value, sort: Math.random() }))
			.sort((a, b) => a.sort - b.sort)
			.map(({ value }) => value);

		const teamToAdd = teams.filter(team => team.name).map(team => {
			return {
				...team,
				members: [],
			};
		});

		const updatedTeams = shuffleUsers.reduce((acc, email, index) => {
			const teamIndex = index % teamToAdd.length;
			acc[teamIndex] = {
				...acc[teamIndex],
				members: [...acc[teamIndex].members, email],
			};
			return acc;
		}, [...teamToAdd]);

		try {
			const res = await api.put(`/events/${eventId}/teams`, updatedTeams);

			if (res.status === 200) {
				dispatch(appActions.setLoading(false));
				setTeams(res.data);
				getEvent();
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.teams.randomUsers.warning'),
				close: true,
			}));
		}
	};
    
	return (
		<ModalWrapperChildren
			modalVisible={modalVisible}
			setModalVisible={() => setModalVisible(false)}
			title={t('events.teams.randomUsers.title')}
			closeButtonName={t('events.teams.randomUsers.cancel')}
			confirmButtonName={t('events.teams.randomUsers.close')}
			onConfirm={() => {randomUsers(),  setModalVisible(false);}}
			withFooter
		>
			<div className={ styles["teams__random-users"] }>
				<span>{t('events.teams.randomUsers.description')}</span>
			</div>
		</ModalWrapperChildren>
	);
};

ModalRandomUsers.propTypes = {
	modalVisible: PropTypes.bool, 
	setModalVisible: PropTypes.func, 
	eventId: PropTypes.string,
	setTeams: PropTypes.func, 
	getEvent: PropTypes.func,
	teams: PropTypes.array,
};

export default ModalRandomUsers;