import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import api from "@/services/api";
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alertActions } from "@/store/actions/alert.actions";

import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";
import styles from "@Pages/Event/Event.module.sass";

const ModalAddUsersTeam = ({modalVisible, setModalVisible, attendees, eventId, teamForModal, setTeams, getEvent, dataToSend}) => {
	const [members, setMembers] = useState([]);
	const [membersToAdd, setMembersToAdd] = useState([]);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const addremoveUserId = (e, user) => {
		if(e.target.checked) {
			setMembersToAdd([...membersToAdd, user]);
		} else if (!e.target.checked) {
			setMembersToAdd(membersToAdd.filter(u => u != user));
		}
	};

	const findMembers = () => {
		const all = attendees.reduce((result, attendee) => {
			if(attendee.team === teamForModal || attendee.team === null) {
				attendee.users.map(user => {
					result.push(user);
				});
			}
			return result;
		}, []);
		setMembers(all);

		const checked = attendees.reduce((result, attendee) => {
			if(attendee.team === teamForModal) {
				attendee.users.map(user => {
					result.push(user);
				});
			}
			return result;
		}, []);
		setMembersToAdd(checked);
	};
    
	const addUsersToTeam = async () => {
		dispatch(appActions.setLoading(true));
		const data = dataToSend.map(obj => {
			
			if(obj.name === teamForModal) {
				return {
					...obj,
					members: membersToAdd,
				};
			} else {
				return {
					...obj,
					members: obj.members.filter(member => !membersToAdd.includes(member)),
				};
			}
		}).filter(team => team.name);
		
		try {
			const res = await api.put(`/events/${eventId}/teams`, data);

			if (res.status === 200) {
				dispatch(appActions.setLoading(false));
				setTeams(res.data);
				getEvent();
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.teams.addUsersTeam.warning'),
				close: true,
			}));
		}
	};	

	useEffect(() => {
		findMembers();
	}, [teamForModal, attendees, modalVisible]);

	return (
		<ModalWrapperChildren
			modalVisible={modalVisible}
			closeButtonName={t('events.teams.addUsersTeam.cancel')}
			setModalVisible={() => setModalVisible(false)}
			confirmButtonName={t('events.teams.addUsersTeam.close')}
			onConfirm={() => {addUsersToTeam(), setModalVisible(false);}}
			withFooter
		>
			<div className={ styles["teams__add-users"] }>
				<p className={ styles["teams__add-users__team"] }>{teamForModal}</p>
				<p className={ styles["teams__add-users__title"] }>{t('events.teams.addUsersTeam.assigneDeleteUsers')}</p>
				<div className={ styles["teams__add-users__list"] }>
					{attendees.length === 0 &&
						<p className={ styles["teams__add-users__name"] }>{t('events.teams.addUsersTeam.noAttendees')}</p>}
					{attendees.length > 0 && members.length === 0 &&
						<p className={ styles["teams__add-users__name"] }>{t('events.teams.addUsersTeam.allAlreadyAssigned')}</p>}
					{members.map(user => {
						return (
							<div key={user.userId} className={ styles["teams__add-users__checkbox-container"] }>
								<input id={`user-${user.userId}`}
									className={ styles["teams__add-users__checkbox"] }
									type='checkbox'
									checked={membersToAdd.includes(user)}
									onChange={(e) => addremoveUserId(e, user)}
								/>
								<label htmlFor={`user-${user.userId}`} className={ styles["teams__add-users__name"] }>{user.name}</label>
							</div>);
					})}
				</div>
			</div>	
		</ModalWrapperChildren>
	);
};

ModalAddUsersTeam.propTypes = {
	modalVisible: PropTypes.bool, 
	setModalVisible: PropTypes.func, 
	eventId: PropTypes.string,
	teamForModal: PropTypes.string, 
	setTeams: PropTypes.func, 
	getEvent: PropTypes.func,
	dataToSend: PropTypes.array,
	attendees: PropTypes.array,
};

export default ModalAddUsersTeam;